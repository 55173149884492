import React from "react";
import { Text, Heading, Link } from "../components/typography";
import { Layout } from "../components/layout";
import "./info.scss";

/* TODO: Update description as needed. */

const InfoPage = () => {
  return (
    <Layout className="info-page" wide seoData={{ title: "Info" }}>
      <div className="info-page--content">
        <Heading size="small" level="1" className="info-page--headline">
          Andrew Duna is a multidisciplinary designer and artist.
        </Heading>
        <Text size="large" className="info-page--bio">
          By profession, he is a UX designer and front-end developer, currently
          serving as a Senior Product Designer at{" "}
          <Link external newTab linkUrl="https://cariloop.com">
            Cariloop
          </Link>
          . Beyond work, he is simply a creative spirit using art to explore
          identity, purpose, and whimsy.
        </Text>
      </div>
    </Layout>
  );
};

export default InfoPage;
